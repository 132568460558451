import React, { HTMLInputTypeAttribute, useState } from "react";
import { Input as NUIInput } from "@nextui-org/react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";

export interface InputProps {
  label: string;
  type: HTMLInputTypeAttribute;
  placeholder?: string;
  autoComplete?: string;
  register?: UseFormRegisterReturn;
  errorMessage?: string;
}

const Input = ({
  label,
  type,
  placeholder,
  autoComplete = "off",
  register,
  errorMessage,
}: InputProps) => {
  const [isVisible, setIsVisible] = useState(type !== "password");

  const getPasswordIcon = () => {
    return (
      <button
        className="focus:outline-none h-8 w-8 text-secondary"
        type="button"
        onClick={() => setIsVisible(!isVisible)}
        aria-label="toggle password visibility"
      >
        {isVisible ? <EyeSlashIcon /> : <EyeIcon />}
      </button>
    );
  };

  const getType = () => {
    if (type === "password" && isVisible) return "text";
    return type;
  };

  return (
    <NUIInput
      label={label}
      {...register}
      placeholder={placeholder}
      variant="bordered"
      size="sm"
      classNames={{
        inputWrapper: ["group-data-[focus=true]:border-primary"],
      }}
      type={getType()}
      endContent={type === "password" ? getPasswordIcon() : null}
      className="mb-6"
      isInvalid={!!errorMessage}
      autoComplete={autoComplete}
      errorMessage={errorMessage}
    />
  );
};

export default Input;
