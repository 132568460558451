import React from "react";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { getSessionRequest } from "../../../api";
import { setTmpSessionId } from "../../../store/reducers/session";
import {
  ErrorDetail,
  SessionRoute,
  SessionSectionContext,
  SessionState,
} from "../session";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";
import extensionSendMessage from "../../../utils/extensionCom";
import { INIT_RESOLVE_EVENT } from "@lumen-developer/lumen-common-js/esm/brokers";

export interface SessionStartContext extends SessionSectionContext {
  trackerRef: React.RefObject<HTMLDivElement>;
  panelist: string;
}
export const SessionStart = () => {
  const { broker, dispatch, panelist, trackerRef, errorHandle } =
    useOutletContext<SessionStartContext>();
  const navigate = useNavigate();

  const initBroker = async () => {
    const div = trackerRef.current;
    if (!div) throw ErrorFormatter.getError(ErrorClass.TRACKER_DIV);
    await broker.init(30000, div, {
      resolveEvent: INIT_RESOLVE_EVENT.GAZE_DETECTOR_INIT,
    });
  };

  // TODO: Check if we want camera on startup
  // useEffect(() => {
  //   const startUp = async () => {
  //     try {
  //       let permRes = await navigator.permissions.query({ name: "camera" });
  //       if (permRes.state === "granted") {
  //         await initBroker();
  //       }
  //     } catch(e) {
  //       console.warn(e)
  //     }
  //   }
  //   startUp();
  // }, []);

  const cont = async () => {
    try {
      const m: PanelMessages.ExternalMessage = {
        type: PanelMessages.ExternalMessageType.CheckPermissions,
      };
      const perms: boolean = await extensionSendMessage(m);
      if (!perms) {
        throw ErrorClass.CAMERA_PERMISSIONS;
      }
      const res = await getSessionRequest(panelist);
      dispatch(setTmpSessionId(res.tmpSessionId));
      // TODO: HANDLE GET SESSION ID ERROR
      if (!broker.state.initialised) {
        await initBroker();
      }
      navigate(SessionRoute.LIGHTING);
    } catch (e) {
      if (e === ErrorClass.EXTENSION_COMMS_FAIL) {
        window.location.href = "/install";
      } else if (e === ErrorClass.CAMERA_PERMISSIONS) {
        const detail: ErrorDetail = {
          fmt: ErrorFormatter.getError(e),
          action: () => window.location.reload(),
          route: SessionRoute.ERROR,
        };
        errorHandle(detail);
      } else {
        errorHandle(e);
      }
    }
  };
  return (
    <>
      <div className="mb-10">
        <Button color="secondary" onClick={cont}>
          Start a 10 minute Research session
        </Button>
      </div>
      <p className="mb-10">Note: We do not record pictures or video of you</p>
    </>
  );
};
