import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { SessionRoute, SessionSectionContext, SessionState } from "../session";

export interface SessionLightingContext extends SessionSectionContext {
  setLightingStatus: (score: number) => void;
}
export const SessionLighting = () => {
  const { broker, setLightingStatus } =
    useOutletContext<SessionLightingContext>();
  const navigate = useNavigate();
  const [score, setScore] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const cont = () => {
    broker.clearQualityTimeout();
    navigate(SessionRoute.POSITION);
  };
  // let throttle = Date.now();

  useEffect(() => {
    broker.getEyeIlluminationQuality((res) => {
      setLightingStatus(res * 100);
      setScore(res * 100);
      // throttle = Date.now();
    });
  }, []);

  return (
    <>
      <p className="font-semibold mb-10">Step 1: Lighting</p>
      <p className="mb-10">So we can see your eyes, being in a well lit room is best.</p>
      <p className="mb-10">
        To continue, a lighting score of 5 or more is required.
      </p>
      <Button
        color="secondary"
        onClick={cont}
        disabled={score < 5}
        ref={buttonRef}
      >Continue</Button>
    </>
  );
};
