import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "./hooks/store";
import { FocusNavbar } from "./components/navbar/navbar";
import { SessionRoute } from "./components/session/session";

const Root = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
  const { pathname } = useLocation();
  const isSessionRoute =
    Object.values(SessionRoute).includes(pathname as SessionRoute) &&
    isLoggedIn;

  return (
    <div>
      {isLoggedIn ? <FocusNavbar /> : null}
      <main
        className={`light text-foreground bg-background relative ${isLoggedIn ? "h-main" : "h-screen"}`}
      >
        <div
          className={`p-8 lg:p-16 flex flex-col ${isSessionRoute ? "lg:flex-row gap-10 lg:gap-0" : "justify-self-center h-full w-full md:w-3/4 lg:w-1/2"}`}
        >
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Root;
