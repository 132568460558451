import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useRollbar } from "@rollbar/react";
import { Button } from "@nextui-org/react";
import { ErrorDetail, SessionSectionContext } from "../session";
import { LOGLEVEL } from "../../../utils/errorFormat";

export interface SessionErrorContext extends SessionSectionContext {
  errorDetail: ErrorDetail;
}
export const SessionError = () => {
  const { broker, dispatch, errorDetail } =
    useOutletContext<SessionErrorContext>();

  const rollbar = useRollbar();

  useEffect(() => {
    switch (errorDetail.fmt.logLevel) {
      case LOGLEVEL.ERROR:
        rollbar.error(errorDetail.fmt.internalMessage);
        break;
      case LOGLEVEL.WARN:
        rollbar.warn(errorDetail.fmt.internalMessage);
        break;
      case LOGLEVEL.INFO:
        rollbar.info(errorDetail.fmt.internalMessage);
        break;
      case LOGLEVEL.SILENT:
      default:
        break;
    }
  }, []);

  return (
    <>
      <p className="font-semibold mb-10">Error!</p>
      <p className="mb-10 text-red-500">{errorDetail.fmt.safeMessage}</p>
      <p className="mb-10">Please use the button below to try again.</p>
      <Button color="secondary" onClick={errorDetail.action}>
        Continue
      </Button>
    </>
  );
};
