import { Button } from "@nextui-org/react";
import React from "react";

const Install = () => {
  const openChromeStore = () => {
    window
      .open(
        `https://chromewebstore.google.com/detail/the-focus-project/${
          process.env.EXTENSION_ID
        }`,
        "_blank",
      )
      ?.focus();
  };
  return (
    <div className="w-1/2">
      <h1 className="font-semibold mb-10 text-2xl">
        Download the Browser Extension
      </h1>
      <div className="mb-10">
        <Button onClick={openChromeStore} color="secondary">
          Download
        </Button>
      </div>
      <p className="font-semibold mb-10">Overview</p>
      <p className="mb-10">
        Why join:
        <br />
        The Focus Project is a chance to earn points that can be exchanged for
        rewards.
      </p>
      <p className="mb-10">
        What you wil be doing:
        <br />
        Participating in a market research panel that aims to understand what
        people pay attention too while use the internet. We do this via eye
        tracking by predicting where you look by using the camera of your
        device.
      </p>
      <p>
        How does it work:
        <br />
        Each time you have a web session or complete a task using our app we
        capture two sets of data:
      </p>
      <ol className="mb-10 list-decimal list-inside">
        <li>
          Eye tracking data by turning on your camera and asking you to do a
          calibration task
        </li>
        <li>Information about the websites you visit</li>
      </ol>
      <p className="mb-10">
        Upon successful completion of a session or task you will earn points!
      </p>
    </div>
  );
};

export default Install;
